<template>
  <Teleport to="#notification-area">
    <Transition
      enter-active-class="transform ease-out duration-300 transition"
      enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
      enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
      leave-active-class="transition ease-in duration-100"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div
        v-if="isVisible" 
        class="bg-background text-background-fg pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5"
      >
        <div class="p-4">
          <div class="flex items-start">
            <div class="flex-shrink-0">
              <template v-if="type === 'success'">
                <IconHeroiconsOutlineCheckCircle
                  class="text-green h-6 w-6"
                  aria-hidden="true"
                />
              </template>

              <template v-else-if="type === 'error'">
                <IconHeroiconsOutlineExclamation
                  class="text-error h-6 w-6"
                  aria-hidden="true"
                />
              </template>

              <template v-else-if="type === 'info'">
                <IconHeroiconsOutlineInformationCircle
                  class="h-6 w-6 text-blue-400"
                  aria-hidden="true"
                />
              </template>
            </div>
            <div class="ml-3 w-0 flex-1 pt-0.5">
              <p class="text-sm">
                {{ message }}
              </p>
            </div>
            <div class="ml-4 flex flex-shrink-0">
              <button
                type="button"
                class="inline-flex rounded-md"
              >
                <span class="sr-only">Close</span>
                <IconHeroiconsOutlineX
                  class="h-5 w-5"
                  aria-hidden="true"
                  @click="emit('close')"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  </Teleport>
</template>

<script setup lang="ts">
import { ref } from 'vue';

const isVisible = ref(true); // Use ref for reactivity

const props = withDefaults(
  defineProps<{
    message?: string
    type?: "error" | "info" | "success"
    timeout?: number
  }>(),
  {
    message: "",
    type: "success",
    timeout: 5000,
  }
)

const emit = defineEmits<{
  (event: "close"): void
}>()

onMounted(() => {
  if (props.timeout) {
    setTimeout(() => {
      destroySelf()
    }, props.timeout)
  }
})

const destroySelf = () => emit("close")
</script>
