import { api } from "@/api"
import { useQuery } from "@tanstack/vue-query"
import type { MaybeRef } from "@vueuse/core"

const EF_UNIT_QUERY_KEY = "ef-units"

export type EFCategory =
  | "yurts"
  | "yurts_ua"
  | "safari_tents"
  | "safari_tents_ua"
  | "glamping_pods"
  | "glamping_pods_ua"
  | "camping_pods"
  | "camping_pods_ua"
  | "glamping_cabins"
  | "glamping_cabins_ua"

export type EFUnitType = {
  id: string
  name: string
  category: EFCategory
  siteId: string
}

export type EFUnit = {
  id: string
  name: string
  code: string
  type: EFUnitType
}

export type EFUnitFilter = {
  siteId: string | null
}

export const listEFUnits = async (filter: EFUnitFilter) => {
  const { body } = await api.get<EFUnit[]>("/ef-units/", {
    query: filter,
  })
  return body
}

export const useEFUnits = (
  query?: MaybeRef<EFUnitFilter>
) => {
  const { id: currentSiteId } = useCurrentSite()

  return useQuery({
    queryKey: [
      EF_UNIT_QUERY_KEY,
      computed(() => ({
        siteId: currentSiteId.value,
        ...unref(query),
      })),
    ] as const,
    queryFn: ({ queryKey: [_, query_] }) =>
      listEFUnits(unref(query_)),
    refetchIntervalInBackground: false,
    refetchInterval: 0,
    staleTime: 60 * 60 * 1000,
  })
}
