<template>
  <div class="w-full px-4 sm:px-6 lg:px-8">
    <div class="sm:flex sm:items-center">
      <div class="sm:flex-auto">
        <h1 class="text-xl font-semibold text-gray-900">
          Bookings
        </h1>
      </div>
    </div>

    <div class="mt-8 flex flex-col">
      <div
        class="mb-4 flex w-full flex-wrap items-center justify-between"
      >
        <BaseSegmentedControl
          v-model="localFilters.status"
          :options="statusFilterOptions"
        />
        <BaseDateIntervalSelect v-model="datePickerModel" />
      </div>
      <div
        class="mb-4 flex w-full flex-wrap items-center justify-between"
      >
        <FilterToggle
          :model-value="bookingTypeToggleModel"
          :options="bookingTypeToggleOptions"
          @update:model-value="setBookingTypeToggleModel"
        />

        <FilterToggle
          v-model="localFilters.abf"
          :options="abfFilterOptions"
        />
      </div>
      <div>
        <div
          class="mb-4 flex w-full flex-wrap justify-between gap-2"
        >
          <BaseInputSearch v-model="localFilters.search" />
          <BasePagination
            v-model="page"
            :page-count="pageCount"
          />
        </div>
        <div
          class="mb-4 flex w-full flex-wrap justify-between gap-2"
        >
          <BaseCountSelect v-model="paginationCount" />
          <button
            type="button"
            class="text-primary border-1 border-primary relative rounded-lg px-2 py-1"
            :disable="isFetching"
            @click="() => refetch()"
          >
            refresh
            <template v-if="isFetching">
              <BaseContainerLoading
                spinner-class="h-4 w-4 "
              />
            </template>
          </button>
        </div>
        <BaseTable
          key-field="id"
          :sort-by="localFilters.sortBy"
          :rows="rows"
          :columns="columns"
          row-class="cursor-pointer"
          :is-loading="isFetching"
          @click-row="onClickRow"
          @update:sort-by="onUpdateSortBy"
        >
          <template #body-cell:bookingRef="{ value }">
            <div class="p-2">
              <div
                :class="[
                  'font-sm rounded-full px-4 py-2 text-center font-semibold',
                  value.bookingType === 'ef'
                    ? 'text-primary-fg bg-[#2c94a7]'
                    : 'bg-primary text-primary-fg',
                ]"
              >
                {{ value.bookingRef }}
              </div>
            </div>
          </template>

          <template
            #body-cell:checked_in_at="{
              value: { checkedInAt, arrivedAt },
            }"
          >
            <div>
              <template v-if="checkedInAt">
                <p class="font-semibold text-black">
                  {{
                    formatDatetimeToDatetimeFull(
                      checkedInAt
                    )
                  }}
                </p>
              </template>
              <template v-if="arrivedAt">
                <p>
                  Arrived:
                  {{
                    formatDatetimeToDatetimeFull(arrivedAt)
                  }}
                </p>
              </template>
            </div>
          </template>

          <template
            #body-cell:bookingPeriod="{
              value: { startsAt, endsAt },
            }"
          >
            <div>
              <p class="font-semibold text-black">
                {{
                  getNumberOfNightsBetweenDates(
                    startsAt,
                    endsAt
                  )
                }}
                night(s)
              </p>
              <p>
                {{ formatStartToEnd(startsAt, endsAt) }}
              </p>
            </div>
          </template>

          <template
            #body-cell:balanceDue="{
              value: { value, autoBalance },
            }"
          >
            <div
              class="space-between flex justify-end space-x-2"
            >
              <template v-if="autoBalance">
                <span
                  class="text-green border-1 border-green group relative rounded-lg px-1"
                >
                  ABF
                  <div
                    class="text-background-fg bg-background border-1 border-gray/10 invisible absolute bottom-0 -mb-6 rounded px-1 group-hover:visible"
                  >
                    Auto Balance Flag
                  </div>
                </span>
              </template>
              <span>
                {{ value }}
              </span>
            </div>
          </template>

          <template #body-cell:note="{ value }">
            <template v-if="value">
              <BookingNote :note="value" />
            </template>
          </template>

          <template #body-cell:pitch="{ value }">
            <div v-if="value.bookingType !== 'ef'">
              <template v-if="value.pitchNumber">
                <p class="font-semibold text-black">
                  {{ value.pitchNumber }}
                </p>
              </template>
              <template v-else>
                <a
                  class="text-primary cursor-pointer underline"
                >
                  Add Pitch
                </a>
              </template>
              <p>
                {{ value.pitchType }}
              </p>
            </div>
            <div v-else>
              <p class="font-semibold text-black">
                {{ value.efUnitName }}
              </p>
              <p>
                {{ value.efUnitTypeName }}
              </p>
            </div>
          </template>

          <template #body-cell:customer_name="{ value }">
            <CustomerItem :customer="value" />
          </template>

          <template #body-cell:vehicles="{ value }">
            <span>
              <p class="text-md font-bold uppercase">
                {{
                  value.primaryVehicleBooking?.registration
                }}
              </p>
              <div class="divide-x-1 -mx-1">
                <template
                  v-for="vehicleBooking of value.vehicleBookings"
                  :key="vehicleBooking.vehicleRegistation"
                >
                  <span class="px-1 uppercase">
                    {{ vehicleBooking.registration }}
                  </span>
                </template>
              </div>
            </span>
          </template>

          <template #body-cell:status="{ value }">
            <BookingStatusChip :status="value" />
          </template>

          <template #body-cell:alert="{ value }">
            <template v-if="value">
              <IconHeroiconsSolidExclamationCircle
                class="text-error h-6 w-6"
              />
            </template>
            <template v-else>
              <div class="h-6 w-6" />
            </template>
          </template>
        </BaseTable>
      </div>
    </div>
  </div>

  <!-- Shows the booking modal -->
  <RouterView
    :key="$route.fullPath"
    @close="onModalClose"
  />
</template>

<script setup lang="ts">
import type { Booking } from "@/services/bookings/bookings"
import type { Customer } from "@/services/bookings/customers"
import {
  type ListBookingsQuery,
  type ListBookingsSortbyOption,
  type BookingStatus,
  isListBookingsSortbyOption,
} from "@/services/bookings/listBookings"
import type {
  BaseRow,
  ColumnDefinition,
} from "@/types/table"
import type { BookingType } from "@/services/bookings/bookingTypes"

const router = useRouter()

const { options: bookingTypeOptions } = useBookingTypes()

const bookingTypeToggleOptions = computed(
  () => bookingTypeOptions.value
)
const bookingTypeToggleModel = computed<BookingType[]>(
  () => bookingTypeToggleModelLocal.value
)
const bookingTypeToggleModelLocal = ref<BookingType[]>(
  bookingTypeOptions.value.map(({ value }) => value)
)
const setBookingTypeToggleModel = (
  newValue: BookingType[]
) => {
  bookingTypeToggleModelLocal.value = newValue
}

const bookingTypeFilters = computed(() => ({
  include_booking_types: bookingTypeToggleModel.value,
}))
const columns: ColumnDefinition<Booking>[] = [
  {
    label: "",
    name: "alert",
    autoWidth: true,
    getValue: (row) => row.hasAlert,
    align: "center",
  },
  {
    label: "Booking Ref.",
    name: "bookingRef",
    autoWidth: true,
    getValue: (row) => ({
      bookingType: row.bookingType,
      bookingRef: row.bookingRef,
    }),
    align: "center",
  },

  {
    label: "Balance Due",
    name: "balanceDue",
    autoWidth: true,
    align: "right",
    getValue: (row) => ({
      value: formatToCurrencyString(row.balanceDue),
      autoBalance: row.autoBalance,
    }),
  },

  {
    label: "Member",
    name: "customer_name",
    getValue: (row): Customer => row.customer,
    isSortable: true,
  },

  {
    label: "Status",
    name: "status",
    getValue: (row) => row.status,
  },

  {
    label: "Checked In Time",
    name: "checked_in_at",
    getValue: (row) => ({
      checkedInAt:
        row.checkedInAt &&
        DateFns.parseISO(row.checkedInAt),
      arrivedAt:
        row.arrivedAt && DateFns.parseISO(row.arrivedAt),
      expected: DateFns.parseISO(row.startsAt),
    }),
    isSortable: true,
  },

  {
    label: "Booking Period",
    name: "bookingPeriod",
    getValue: (row) => ({
      startsAt: DateFns.parseISO(row.startsAt),
      endsAt: DateFns.parseISO(row.endsAt),
    }),
  },

  {
    label: "Pitch/Unit",
    name: "pitch",
    getValue: (row) =>
      row.bookingType == "ef"
        ? {
            bookingType: row.bookingType,
            efUnitName: row.efUnitName,
            efUnitTypeName: row.efUnitTypeName,
          }
        : {
            bookingType: row.bookingType,
            pitchType: row.pitchType,
            pitchNumber: row.pitchNumber,
          },
    align: "center",
  },

  {
    label: "Vehicles",
    name: "vehicles",
    getValue: (row) => ({
      primaryVehicleBooking: row.primaryVehicleBooking,
      vehicleBookings: row.vehicleBookings,
    }),
  },

  {
    label: "Note",
    name: "note",
    getValue: (row) => row.note,
  },
]

const getNumberOfNightsBetweenDates = (
  date1: Date,
  date2: Date
): number =>
  Math.abs(DateFns.differenceInCalendarDays(date1, date2))

const formatStartToEnd = (start: Date, end: Date): string =>
  `${formatDatetimeToDatePart(
    start
  )} - ${formatDatetimeToDatePart(end)}`

const onClickRow = (row: BaseRow) => {
  router.push({
    name: "bookingInstance",
    params: {
      bookingId: row.id,
    },
  })
}

const onModalClose = () => {
  router.push({
    name: "bookings",
  })
}

const localFilters = reactive<{
  status: BookingStatus | null
  sortBy: ListBookingsSortbyOption | null

  search: string
  abf: ("abf" | "noabf")[]
}>({
  status: null,
  sortBy: null,

  search: "",
  abf: ["abf", "noabf"],
})

const { datePickerModel } = useDatePickerContext()
const filters = reactiveComputed(() => ({
  ...localFilters,
  ...bookingTypeFilters.value,
  ...{
    fromDt: datePickerModel.value.start,
    untilDt: datePickerModel.value.end,
  },
}))
const statusFilterOptions = [
  {
    label: "All",
    value: null,
  },
  {
    label: "Scheduled",
    value: "scheduled",
  },
  {
    label: "Expected",
    value: "expected",
  },
  {
    label: "Arrived",
    value: "arrived",
  },
  {
    label: "Checked In",
    value: "checkedin",
  },
  {
    label: "No Show",
    value: "noshow",
  },
]

const abfFilterOptions = [
  {
    label: "No ABF",
    value: "noabf",
  },
  {
    label: "ABF",
    value: "abf",
  },
]

const paginationCount = ref(10)

const { pagination, page, pageCount, resetPagination } =
  usePagination({
    limit: paginationCount,
    count: computed(() => data.value?.count || 0),
  })

watch(
  () => filters,
  () => resetPagination(),
  { deep: true, immediate: true }
)

const query = reactiveComputed<ListBookingsQuery>(() => ({
  ...filters,
  ...pagination.value,
}))

const onUpdateSortBy = (value: string | null) => {
  if (value === null || isListBookingsSortbyOption(value)) {
    localFilters.sortBy = value
  }
}

const { data, isFetching, refetch } = useBookings(query)

const rows = computed(() => data.value?.items ?? [])

const sortByState = reactive<
  Map<BookingStatus | null, ListBookingsSortbyOption | null>
>(
  new Map([
    [null, null],
    ["arrived", "-checked_in_at"],
    ["checkedin", "-checked_in_at"],
    ["expected", null],
    ["noshow", null],
    ["scheduled", null],
    ["unknown", null],
  ])
)

watch(
  () => filters.status,
  (value) => {
    localFilters.sortBy = sortByState.get(value) ?? null
  }
)

watch(
  () => filters.sortBy,
  (value) => {
    sortByState.set(filters.status, value)
  }
)
</script>
