<template>
  <BaseForm
    class="h-70vh flex flex-col space-y-4"
    @submit="onSubmit"
  >
    <div
      class="h-full flex-1 space-y-4 overflow-y-auto px-2"
    >
      <section class="grid grid-cols-4 gap-4">
        <BaseInputText
          v-if="!isEdit"
          v-model="form.bookingRef"
          label="Booking Reference"
          class="col-span-4 sm:col-span-2"
          :disabled="isEdit"
          name="bookingRef"
          :rules="rules.bookingRef"
        />
        <BaseInputField
          v-if="isEdit"
          label="Arrival Time"
          class="col-span-4 sm:col-span-2"
        >
          <template v-if="initialData?.arrivedAt">
            {{
              formatDatetimeToDatetimeFull(
                parseISO(initialData.arrivedAt)
              )
            }}
          </template>
          <template v-else-if="initialData?.startsAt">
            <span class="text-background-fg/50">
              Exp.
              {{
                formatDatetimeToDatetimeFull(
                  parseISO(initialData.startsAt)
                )
              }}
            </span>
          </template>
        </BaseInputField>
        <div class="col-span-4 sm:col-span-2">
          <BaseInputCurrency
            v-model="form.balanceDue"
            label="Balance due"
            name="balanceDue"
            class="mb-3"
          />
          <BaseInputToggle
            v-model="form.autoBalance"
            label="Pay Balance the Night Before Your Stay"
            name="autoBalance"
            :disabled="isEdit"
          />
        </div>

        <BaseInputField
          label="Booking Period"
          class="col-span-4 sm:col-span-2"
        >
          <div
            class="flex w-full flex-col items-start justify-between gap-2"
          >
            <BaseInputDatetime
              v-model="form.startsAt"
              :rules="rules.startsAt"
            />

            <span
              class="text-background-fg/70 pl-2 text-xs"
            >
              to
            </span>

            <BaseInputDatetime
              v-model="form.endsAt"
              :rules="rules.endsAt"
            />
          </div>
        </BaseInputField>

        <BaseInputSelect
          v-model="form.siteId"
          :options="siteOptions"
          class="col-span-4 sm:col-span-2"
          label="Site"
          name="siteId"
          :rules="rules.siteId"
        />

        <template v-if="!isEFBooking">
          <BaseInputSelect
            v-model="form.pitchType"
            :options="pitchTypeOptions"
            class="col-span-4 sm:col-span-2"
            label="Pitch Type"
            name="pitchType"
            :disabled="!form.siteId"
            :rules="rules.pitchType"
          />
          <BaseInputText
            v-model="form.pitchNumber"
            label="Pitch"
            class="col-span-4 sm:col-span-2"
            name="pitchNumber"
          />
        </template>

        <template v-else>
          <BaseInputSelect
            v-model="form.efUnitId"
            :options="efUnitOptions"
            class="col-span-4 sm:col-span-2"
            label="Unit"
            name="efUnitId"
            :disabled="!form.siteId"
            :rules="rules.efUnitId"
          />
        </template>

        <BaseInputSelect
          v-model="form.bookingType"
          class="col-span-2"
          :options="bookingTypeOptions"
          label="Booking Type"
        />
      </section>

      <CustomerForm
        v-model:customer="form.customer"
        name="customerId"
        :allow-edit="!props.initialData?.id"
      />

      <section
        class="ring-background-fg/7 grid grid-cols-4 gap-4 p-4 ring-1"
      >
        <h3
          class="text-background-fg/87 col-span-4 font-semibold"
        >
          Vehicles
        </h3>

        <div class="col-span-4 sm:col-span-2">
          <BaseInputText
            v-model="
              form.primaryVehicleBooking.registration
            "
            label="Primary Registration"
            class="mb-3"
            name="vehicleBookings:0:registration"
          />
        </div>

        <BaseInputField
          label="Secondary Registrations"
          class="col-span-4 sm:col-span-2"
        >
          <template
            v-for="(
              vehicleBooking, index
            ) in form.vehicleBookings"
            :key="vehicleBooking.id"
          >
            <BaseInputText
              v-model="vehicleBooking.registration"
              class="mb-3 w-full"
              :name="`vehicleBookings:${
                index + 1
              }:registration`"
            />
          </template>
          <template v-if="form.vehicleBookings.length < 2">
            <button
              type="button"
              class="ring-background-fg text-background-fg hover:bg-background-fg/10 w-full rounded py-2 ring-1"
              @click="addSecondaryregistration"
            >
              Add Registration
            </button>
          </template>
        </BaseInputField>
      </section>

      <section class="grid grid-cols-4 gap-4">
        <BaseInputTextarea
          v-model="form.note"
          label="Note"
          class="col-span-4"
          name="note"
        />
      </section>
    </div>

    <div
      class="grid w-full flex-shrink-0 grid-cols-4 items-center justify-end gap-4"
    >
      <button
        class="bg-background-fg/30 col-span-4 rounded-md px-4 py-2 text-sm text-white sm:col-span-1 sm:col-start-3"
        type="button"
        @click="onCancel"
      >
        Cancel
      </button>

      <button
        type="submit"
        class="bg-primary text-primary-fg col-span-4 rounded-md px-4 py-2 text-sm sm:col-span-1 sm:col-start-4"
        >Save Booking</button
      >
    </div>

    <template v-if="isSubmitting">
      <BaseContainerLoading />
    </template>
  </BaseForm>
</template>

<script setup lang="ts">
import type { Booking } from "@/services/bookings/bookings"
import type { BookingForm } from "@/services/bookings/bookingForm"
import { ApiError } from "@/api"
import type { FormSubmitHelpers } from "@/composables/useFormContext"

const router = useRouter()

const parseISO = DateFns.parseISO
const props = defineProps<{
  initialData?: Booking | undefined
}>()

const emit = defineEmits<{
  (event: "close"): void
}>()

const { options: bookingTypeOptions } = useBookingTypes()

const form = getBookingFormState(props.initialData)

const addSecondaryregistration = () => {
  form.vehicleBookings = [
    ...form.vehicleBookings,
    {
      id: null,
      registration: "",
      isPrimary: false,
    },
  ]
}

const onSubmit = async (helpers: FormSubmitHelpers) => {
  isSubmitting.value = true

  try {
    const data: BookingForm = {
      ...form,
      // Clear pitch data if EF booking
      pitchType: isEFBooking.value ? null : form.pitchType,
      pitchNumber: isEFBooking.value
        ? null
        : form.pitchNumber,
      // Filter out empty vehicle registrations
      vehicleBookings: form.vehicleBookings.filter(
        (v) => !!v.registration
      ),
    }

    if (props.initialData?.id) {
      await updateBooking(props.initialData?.id, data)
      toast.success("Booking updated")
    } else {
      const response = await createBooking(data)
      toast.success("Booking created")
      router.push(response.id)
    }
  } catch (error) {
    if (error instanceof ApiError) {
      if (isEdit.value) {
        toast.error("Failed to update booking")
      } else {
        toast.error("Failed to create booking")
      }

      if (error.requestErrors) {
        helpers.setErrors(error.requestErrors?.body ?? {})
      }
    }
  } finally {
    isSubmitting.value = false
  }
}

const isEdit = computed(() => !!props.initialData?.id)

const isSubmitting = ref(false)

const onCancel = () => emit("close")

const { data: sites } = useSites()
const siteOptions = computed(
  () =>
    sites.value?.map(({ id, name }) => ({
      value: id,
      label: name,
    })) || []
)

const { data: pitchTypes } = usePitchTypes(
  computed(() => ({
    siteId: form.siteId,
  }))
)
const pitchTypeOptions = computed(
  () =>
    pitchTypes.value?.map(({ name }) => ({
      value: name,
      label: name,
    })) || []
)

const { data: efUnits } = useEFUnits(
  computed(() => ({
    siteId: form.siteId,
  }))
)

const efUnitOptions = computed(
  () =>
    efUnits.value?.map((unit) => ({
      value: unit.id,
      label: `${unit.name} (${unit.type.name})`,
    })) || []
)

const isEFBooking = computed(
  () => form.bookingType === "ef"
)

const required = (value: unknown) =>
  !!value || "Field is required"

const rules = computed(() => ({
  bookingRef: [required],
  startsAt: [required],
  endsAt: [required],
  siteId: [required],
  pitchType: !isEFBooking.value ? [required] : [],
  efUnitId: isEFBooking.value ? [required] : [],
}))
</script>
