const currentSiteId = ref<string | null>(null)

export const useCurrentSite = () => {
  return { id: currentSiteId }
}

export const useIsExperienceFreedom = () => {
  const { data: sites } = useSites()
  const { id: currentSiteId } = useCurrentSite()

  return computed(
    () =>
      currentSiteId.value == null ||
      sites.value?.find(
        (site) => site.id === currentSiteId.value
      )?.isExperienceFreedom
  )
}
