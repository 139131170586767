import { cloneDeep } from "lodash"
import type { Booking } from "./bookings"
import type { Customer } from "./customers"
import type { BookingType } from "./bookingTypes"

type BookingFormVehicleBooking = {
  id: string | null // null to create
  registration: string
  isPrimary: boolean | null
}

export type BookingForm = {
  bookingRef: string
  startsAt: string
  endsAt: string
  siteId: string | null
  pitchType: string | null
  pitchNumber: string | null
  efUnitId: string | null
  note: string
  balanceDue: number
  autoBalance: boolean
  customer: Customer | null
  primaryVehicleBooking: BookingFormVehicleBooking
  vehicleBookings: Array<BookingFormVehicleBooking>
  bookingType: BookingType
}

export const getBookingFormState = (
  initialBooking?: Booking
): BookingForm =>
  initialBooking
    ? reactive({
        ...cloneDeep(initialBooking),
        pitchType:
          initialBooking.bookingType != "ef"
            ? initialBooking.pitchType
            : null,
        pitchNumber:
          initialBooking.bookingType != "ef"
            ? initialBooking.pitchNumber ?? ""
            : null,
        efUnitId:
          initialBooking.bookingType == "ef"
            ? initialBooking.efUnitId
            : null,
        startsAt: DateFns.format(
          DateFns.parseISO(initialBooking.startsAt),
          "yyyy-MM-dd'T'HH:mm"
        ),
        endsAt: DateFns.format(
          DateFns.parseISO(initialBooking.endsAt),
          "yyyy-MM-dd'T'HH:mm"
        ),
        primaryVehicleBooking:
          initialBooking.primaryVehicleBooking === null
            ? {
                id: null,
                registration: "",
                isPrimary: true,
              }
            : { ...initialBooking.primaryVehicleBooking },
      })
    : reactive({
        bookingRef: "",
        startsAt: DateFns.format(
          DateFns.set(new Date(), {
            hours: 13,
            minutes: 0,
            seconds: 0,
          }),
          "yyyy-MM-dd'T'HH:mm"
        ),
        endsAt: DateFns.format(
          DateFns.set(DateFns.addDays(new Date(), 7), {
            hours: 12,
            minutes: 0,
            seconds: 0,
          }),
          "yyyy-MM-dd'T'HH:mm"
        ),
        siteId: useCurrentSite().id.value,
        pitchType: null,
        pitchNumber: "",
        efUnitId: null,
        bookingType: "default",
        note: "",
        balanceDue: 0,
        autoBalance: false,
        customer: null,
        vehicleBookings: [],
        primaryVehicleBooking: {
          id: null,
          registration: "",
          isPrimary: true,
        },
      })
