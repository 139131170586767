import type { Customer } from "./customers"

export const BOOKINGS_QUERY_KEY = "bookings"

export const BOOKING_STATUS_MAP = {
  scheduled: {
    label: "Scheduled",
    color: "red",
  },
  expected: {
    label: "Expected",
    color: "amber",
  },
  noshow: {
    label: "No-Show",
    color: "red",
  },
  checkedin: {
    label: "Checked In",
    color: "green",
  },
  arrived: {
    label: "Arrived",
    color: "amber",
  },
  unknown: {
    label: "Unknown",
    color: "gray2",
  },
  seasonal: {
    label: "Seasonal",
    color: "orange",
  },
  storage: {
    label: "Storage",
    color: "gray2",
  },
} as const

// Keep in sync with BookingStatus in bookings.py
export type BookingStatus = keyof typeof BOOKING_STATUS_MAP

export type PitchType = {
  id: string
  name: string
  siteId: string
  colorRgb: string
}

// Keep in sync with VehicleBookingSchema
export type VehicleBooking = {
  id: string
  registration: string
  isPrimary: boolean
  type: string | null
  length: string | null
  trailerLength: string | null
}

// Keep in sync with BookingSchema
export type BookingApiResponseBase = {
  id: string
  bookingRef: string
  balanceDue: number
  autoBalance: boolean
  startsAt: string
  endsAt: string
  arrivedAt: string | null
  checkedInAt: string | null
  siteId: string
  note: string | null
  customer: Customer
  vehicleBookings: VehicleBooking[]
  status: BookingStatus
  hasAlert: boolean
}

export type BookingApiResponseDefault =
  BookingApiResponseBase & {
    bookingType: "default" | "storage" | "seasonal"
    pitchType: string
    pitchNumber: string | null
  }

export type BookingApiResponseEF =
  BookingApiResponseBase & {
    bookingType: "ef"
    efUnitId: string
  }

export type BookingApiResponse =
  | BookingApiResponseDefault
  | BookingApiResponseEF

export type BookingBase = {
  id: string
  bookingRef: string
  balanceDue: number
  autoBalance: boolean
  siteId: string
  startsAt: string
  endsAt: string
  customer: Customer
  vehicleBookings: VehicleBooking[]
  primaryVehicleBooking: VehicleBooking | null
  arrivedAt: string | null
  checkedInAt: string | null
  note: string
  status: BookingStatus
  hasAlert: boolean
}

export type BookingDefault = BookingBase & {
  bookingType: "default" | "storage" | "seasonal"
  pitchType: string
  pitchNumber: string | null
}

export type BookingEF = BookingBase & {
  bookingType: "ef"
  efUnitId: string
}

export type Booking = BookingDefault | BookingEF

export const parseBookingApiResponse = (
  apiResponse: BookingApiResponse
): Booking => {
  const primaryVehicleBooking =
    apiResponse.vehicleBookings.find(
      ({ isPrimary }) => isPrimary
    ) ?? null
  const secondaryVehicleBookings =
    apiResponse.vehicleBookings.filter(
      ({ isPrimary }) => !isPrimary
    )

  return {
    ...apiResponse,
    primaryVehicleBooking,
    vehicleBookings: secondaryVehicleBookings,
    note: apiResponse.note ?? "",
  }
}
