import { createRouter, createWebHistory } from "vue-router"

import BaseLayout from "@/layouts/BaseLayout.vue"

import BookingsList from "@/views/BookingsList.vue"
import CameraEventsList from "@/views/CameraEventsList.vue"
import AllowlistEntriesList from "@/views/AllowlistEntriesList.vue"
import CreateAllowlistEntry from "@/views/CreateAllowlistEntry.vue"
import AllowlistEntryInstance from "@/views/AllowlistEntryInstance.vue"
import LogIn from "@/views/LogIn.vue"
import BookingModal from "@/modals/BookingModal.vue"
import CreateBookingModal from "@/modals/CreateBookingModal.vue"
import ReportIndex from "@/views/ReportIndex.vue"

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      component: BaseLayout,
      children: [
        {
          name: "home",
          path: "",
          redirect: { name: "bookings" },
        },
        {
          name: "bookings",
          component: BookingsList,
          path: "bookings",
          children: [
            {
              name: "bookingInstance",
              path: ":bookingId",
              props: true,
              component: BookingModal,
            },
            {
              name: "bookingCreate",
              path: "create",
              props: true,
              component: CreateBookingModal,
            },
          ],
        },
        {
          name: "allowlist",
          component: AllowlistEntriesList,
          path: "allowlist",

          children: [
            {
              name: "createAllowlistEntry",
              path: "create",
              component: CreateAllowlistEntry,
            },
            {
              name: "allowlistEntryInstance",
              path: ":allowlistEntryId",
              props: true,
              component: AllowlistEntryInstance,
            },
          ],
        },
        {
          name: "cameraEvents",
          component: CameraEventsList,
          path: "camera-events",
        },
        {
          name: "reports",
          component: ReportIndex,
          path: "reports",
        },
        {
          name: "keycodes",
          component: () =>
            import("@/views/KeycodeList.vue"),
          path: "keycodes",
        },
      ],
    },
    {
      name: "logIn",
      component: LogIn,
      path: "/login",
    },
  ],
})

export default router
