<template>
  <BaseForm
    class="grid grid-cols-5 gap-2"
    @submit="handleSave"
  >
    <BaseInputText
      v-model="customerForm.title"
      label="Title*"
      name="title"
      :rules="[required]"
    />

    <BaseInputText
      v-model="customerForm.firstName"
      label="First Name*"
      name="firstName"
      class="col-span-2 sm:col-span-2"
      :rules="[required]"
    />

    <BaseInputText
      v-model="customerForm.lastName"
      class="col-span-2 sm:col-span-2"
      label="Last Name*"
      name="lastName"
      :rules="[required]"
    />
    <div
      class="col-span-full flex items-end justify-around"
    >
      <BaseInputText
        v-model="customerForm.membershipNumber"
        label="Membership Number*"
        name="membershipNumber"
        class="flex-1"
        :rules="[required]"
      />
      <button
        type="button"
        class="border-background-fg/20 hover:bg-amber ml-2 flex w-16 items-center justify-center rounded-full border px-3 py-1 text-sm font-semibold transition hover:bg-opacity-20"
        @click="onGenerateORNumber"
      >
        <template v-if="ORButtonState === 'generate'">
          O.R
        </template>
        <template v-else-if="ORButtonState === 'clear'">
          Clear
        </template>
      </button>
    </div>

    <BaseInputText
      v-model="customerForm.email"
      type="email"
      label="Email"
      class="col-span-full"
      name="email"
    />

    <BaseInputText
      v-model="customerForm.phone"
      label="Phone Number"
      type="tel"
      class="col-span-full"
      name="phone"
    />

    <BaseInputSelect
      v-model="customerForm.language"
      label="Language"
      :options="languageOptions"
      class="col-span-full"
    />

    <div
      class="col-span-full mt-4 grid w-full grid-cols-2 items-center justify-end gap-2"
    >
      <button
        class="bg-background-fg/30 rounded-md px-4 py-2 text-sm text-white"
        type="button"
        @click.stop="$emit('cancel')"
      >
        Cancel
      </button>
      <button
        type="submit"
        class="bg-primary text-primary-fg rounded-md px-4 py-2 text-sm"
      >
        Save</button
      >
    </div>
  </BaseForm>
</template>

<script setup lang="ts">
import { ApiError } from "@/api.js"
import type { FormSubmitHelpers } from "@/composables/useFormContext.js"
import type { Customer } from "@/services/bookings/customers"

const props = defineProps<{ customer: Customer | null }>()
const emits = defineEmits<{
  (event: "cancel"): void
  (event: "update", customer: Customer): void
}>()

const { options: languageOptions } = useLanguages()

const customerForm = ref(
  props.customer === null
    ? {
        id: null,
        title: "",
        firstName: "",
        lastName: "",
        membershipNumber: "",
        membershipActive: true,
        email: "",
        phone: "",
        language: LANGUAGES.english.value,
      }
    : {
        ...props.customer,
        membershipNumber:
          props.customer.membershipNumber ?? "",
        email: props.customer.email ?? "",
        phone: props.customer.phone ?? "",
      }
)

async function handleSave(helpers: FormSubmitHelpers) {
  try {
    let customer: Customer
    if (customerForm.value.id !== null) {
      customer = await updateCustomer(
        customerForm.value.id,
        {
          ...customerForm.value,
          email: customerForm.value.email || null,
          phone: customerForm.value.phone || null,
        }
      )
      toast.success("Customer updated")
    } else {
      customer = await createCustomer({
        ...customerForm.value,
        email: customerForm.value.email || null,
        phone: customerForm.value.phone || null,
      })
      toast.success("Customer created")
    }
    emits("cancel")
    emits("update", customer)
  } catch (error) {
    if (error instanceof ApiError) {
      toast.error("Failed to update customer")
      if (error.requestErrors) {
        helpers.setErrors(error.requestErrors?.body ?? {})
      }
    }
  }
}

const required = (value: unknown) => !!value || "Required"

const ORButtonState = computed<"generate" | "clear" | "default">(() => {
  if (!customerForm.value.membershipNumber) {
    return "generate";
  } else if (customerForm.value.membershipNumber) {
    return "clear";
  }
  return "default"; // Add a default return value
});
const onGenerateORNumber = () => {
  if (ORButtonState.value === "generate") {
    customerForm.value.membershipNumber = `OR${String(
      new Date().getTime()
    ).slice(-6)}`
  } else if (ORButtonState.value === "clear") {
    customerForm.value.membershipNumber = ""
  }
}
</script>
