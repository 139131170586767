export const useBookingTypes = () => {
  const isExperienceFreedom = useIsExperienceFreedom()

  const bookingTypes = computed(() =>
    isExperienceFreedom.value
      ? {
          default: {
            value: "default",
            label: "Standard",
          },
          ef: {
            value: "ef",
            label: "Experience Freedom",
          },
          seasonal: {
            value: "seasonal",
            label: "Seasonal",
          },
          storage: {
            value: "storage",
            label: "Storage",
          },
        }
      : {
          default: {
            value: "default",
            label: "Standard",
          },
          seasonal: {
            value: "seasonal",
            label: "Seasonal",
          },
          storage: {
            value: "storage",
            label: "Storage",
          },
        }
  )

  return {
    data: bookingTypes,
    options: computed(() =>
      Object.values(bookingTypes.value)
    ),
  }
}
