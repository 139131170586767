<template>
  <HeadlessListbox
    v-model="modelValue"
    as="div"
    class="relative"
  >
    <HeadlessListboxLabel class="sr-only">
      Change Site
    </HeadlessListboxLabel>
    <div class="flex justify-end">
      <HeadlessListboxButton
        ref="listboxButtonRef"
        class="text-background-fg/60 text-end flex w-full items-center justify-end rounded-full py-2 px-3 text-base"
      >
        <span class="sr-only">Change site</span>
        <span class="line-clamp-1">
          {{ currentSite?.name || "All Sites" }}</span
        >
        <IconHeroiconsSolidChevronDown
          class="ml-1 h-6 w-6"
          aria-hidden="true"
        />
      </HeadlessListboxButton>
    </div>

    <Teleport to="#listbox-options">
      <Transition
        leave-active-class="transition ease-in duration-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <HeadlessListboxOptions
          ref="listboxOptionsRef"
          class="divide-background-fg/8 bg-background absolute right-0 z-10 max-h-[60vh] w-72 origin-top-right divide-y overflow-hidden overflow-y-auto rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
          as="ul"
        >
          <HeadlessListboxOption
            v-for="option in siteOptions"
            :key="option.id ?? undefined"
            #default="{ active, selected }"
            as="template"
            :value="option.id"
          >
            <li
              :class="[
                active
                  ? 'bg-primary text-white'
                  : 'text-background-fg/87',
                'relative cursor-pointer select-none p-4 text-sm',
              ]"
            >
              <div class="flex flex-col">
                <div class="flex justify-between">
                  <p
                    :class="
                      selected
                        ? 'font-semibold'
                        : 'font-normal'
                    "
                  >
                    {{ option.name }}
                  </p>
                  <span
                    v-if="selected"
                    :class="
                      active
                        ? 'text-white'
                        : 'text-indigo-500'
                    "
                  >
                    <IconHeroiconsSolidCheck
                      class="h-5 w-5"
                      aria-hidden="true"
                    />
                  </span>
                </div>
              </div>
            </li>
          </HeadlessListboxOption>
        </HeadlessListboxOptions>
      </Transition>
    </Teleport>
  </HeadlessListbox>
</template>

<script setup lang="ts">
import { createPopper } from "@popperjs/core"
import { sameWidthModifier } from "@/utils/popperUtils"
import type { ComponentPublicInstance } from "vue"
const { id: modelValue } = useCurrentSite()
const { currentUser } = useCurrentUser()

const currentSite = computed(() =>
  siteOptions.value?.find(
    ({ id }) => id === modelValue.value
  )
)
const { data: sites } = useSites()

const siteOptions = computed(() =>
  sites.value && currentUser.value
    ? [
        ...(currentUser.value.siteId
          ? []
          : [
              {
                id: null,
                name: "All Sites",
              },
            ]),
        ...sites.value,
      ]
    : []
)

watch(
  () => siteOptions.value,
  (newOptions) => {
    const currentValueIsInvalid = !newOptions.some(
      (option) => option.id === modelValue.value
    )

    if (currentValueIsInvalid) {
      const newSiteId =
        newOptions.length > 0 ? newOptions[0].id : null
      modelValue.value = newSiteId
    }
  },
  { deep: true, immediate: true }
)

const listboxOptionsRef =
  ref<ComponentPublicInstance | null>(null)
const listboxButtonRef =
  ref<ComponentPublicInstance | null>(null)

whenever(
  () =>
    listboxButtonRef.value?.$el &&
    listboxOptionsRef.value?.$el,
  () => {
    createPopper(
      listboxButtonRef.value?.$el,
      listboxOptionsRef.value?.$el,
      {
        placement: "bottom-start",
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, 4],
            },
          },
          sameWidthModifier,
        ],
      }
    )
  }
)
</script>
