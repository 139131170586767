import { api } from "@/api"
import { useQuery } from "@tanstack/vue-query"

export const SITE_QUERY_KEY = "sites"

export type Site = {
  id: string
  name: string
  regionId: string
  isTraining: boolean
  isExperienceFreedom: boolean
}

export const listSites = async () => {
  const { body } = await api.get<Site[]>("/sites")

  return body
}

export const useSites = () => {
  return useQuery({
    queryKey: [SITE_QUERY_KEY],
    queryFn: () => listSites(),
    staleTime: 60 * 60 * 1000,
  })
}
